
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as calendargLn4MEFDRQMeta } from "/home/forge/app.canteenweb.com/pages/[establishment_uuid]/cart/calendar.vue?macro=true";
import { default as indexMNc96XrWzjMeta } from "/home/forge/app.canteenweb.com/pages/[establishment_uuid]/cart/index.vue?macro=true";
import { default as successZj8Ri0tMIYMeta } from "/home/forge/app.canteenweb.com/pages/[establishment_uuid]/cart/success.vue?macro=true";
import { default as menu_45calendar3OcYrUAI6aMeta } from "/home/forge/app.canteenweb.com/pages/[establishment_uuid]/menu-calendar.vue?macro=true";
import { default as shop2oTAjPmlHkMeta } from "/home/forge/app.canteenweb.com/pages/[establishment_uuid]/shop.vue?macro=true";
import { default as accountpxhFORbKxQMeta } from "/home/forge/app.canteenweb.com/pages/account.vue?macro=true";
import { default as indexvZOjVmp4MBMeta } from "/home/forge/app.canteenweb.com/pages/add-funds/[amount]/index.vue?macro=true";
import { default as resultppKPy4vn63Meta } from "/home/forge/app.canteenweb.com/pages/add-funds/[reference]/result.vue?macro=true";
import { default as indexXLocfl3eK9Meta } from "/home/forge/app.canteenweb.com/pages/add-funds/index.vue?macro=true";
import { default as deactivatedsuurmeeFDwMeta } from "/home/forge/app.canteenweb.com/pages/deactivated.vue?macro=true";
import { default as delete_45accountaCVsY2SojlMeta } from "/home/forge/app.canteenweb.com/pages/delete-account.vue?macro=true";
import { default as _91diner_uuid_93CtK29v9r5nMeta } from "/home/forge/app.canteenweb.com/pages/diners/[diner_uuid].vue?macro=true";
import { default as addGQ0mLimg9nMeta } from "/home/forge/app.canteenweb.com/pages/diners/add.vue?macro=true";
import { default as indexhpOROLNyD8Meta } from "/home/forge/app.canteenweb.com/pages/diners/index.vue?macro=true";
import { default as addIeqqvwYdc4Meta } from "/home/forge/app.canteenweb.com/pages/establishments/add.vue?macro=true";
import { default as forgot_45passwordxWBJlyGocZMeta } from "/home/forge/app.canteenweb.com/pages/forgot-password.vue?macro=true";
import { default as _91order_uuid_93P95iBxTHbeMeta } from "/home/forge/app.canteenweb.com/pages/history/orders/[order_uuid].vue?macro=true";
import { default as index1PWrJg05fOMeta } from "/home/forge/app.canteenweb.com/pages/history/orders/index.vue?macro=true";
import { default as _91transaction_uuid_93Ju5YieNsgvMeta } from "/home/forge/app.canteenweb.com/pages/history/transactions/[transaction_uuid].vue?macro=true";
import { default as indexPToCxjuMdcMeta } from "/home/forge/app.canteenweb.com/pages/history/transactions/index.vue?macro=true";
import { default as indexof0k9DmzyiMeta } from "/home/forge/app.canteenweb.com/pages/index.vue?macro=true";
import { default as loginEqEiYzaod3Meta } from "/home/forge/app.canteenweb.com/pages/login.vue?macro=true";
import { default as _91token_93d2Lodfht92Meta } from "/home/forge/app.canteenweb.com/pages/password-reset/[token].vue?macro=true";
import { default as profilelI2Wk6dWfQMeta } from "/home/forge/app.canteenweb.com/pages/profile.vue?macro=true";
import { default as signup_45successWNdZ4qSu90Meta } from "/home/forge/app.canteenweb.com/pages/signup-success.vue?macro=true";
import { default as signupJUQQ43Jf60Meta } from "/home/forge/app.canteenweb.com/pages/signup.vue?macro=true";
import { default as update_45appRX6sYScrfYMeta } from "/home/forge/app.canteenweb.com/pages/update-app.vue?macro=true";
export default [
  {
    name: "establishment_uuid-cart-calendar",
    path: "/:establishment_uuid()/cart/calendar",
    meta: calendargLn4MEFDRQMeta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/[establishment_uuid]/cart/calendar.vue")
  },
  {
    name: "establishment_uuid-cart",
    path: "/:establishment_uuid()/cart",
    meta: indexMNc96XrWzjMeta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/[establishment_uuid]/cart/index.vue")
  },
  {
    name: "establishment_uuid-cart-success",
    path: "/:establishment_uuid()/cart/success",
    meta: successZj8Ri0tMIYMeta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/[establishment_uuid]/cart/success.vue")
  },
  {
    name: "establishment_uuid-menu-calendar",
    path: "/:establishment_uuid()/menu-calendar",
    meta: menu_45calendar3OcYrUAI6aMeta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/[establishment_uuid]/menu-calendar.vue")
  },
  {
    name: "establishment_uuid-shop",
    path: "/:establishment_uuid()/shop",
    meta: shop2oTAjPmlHkMeta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/[establishment_uuid]/shop.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: accountpxhFORbKxQMeta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/account.vue")
  },
  {
    name: "add-funds-amount",
    path: "/add-funds/:amount()",
    meta: indexvZOjVmp4MBMeta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/add-funds/[amount]/index.vue")
  },
  {
    name: "add-funds-reference-result",
    path: "/add-funds/:reference()/result",
    meta: resultppKPy4vn63Meta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/add-funds/[reference]/result.vue")
  },
  {
    name: "add-funds",
    path: "/add-funds",
    meta: indexXLocfl3eK9Meta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/add-funds/index.vue")
  },
  {
    name: "deactivated",
    path: "/deactivated",
    component: () => import("/home/forge/app.canteenweb.com/pages/deactivated.vue")
  },
  {
    name: "delete-account",
    path: "/delete-account",
    meta: delete_45accountaCVsY2SojlMeta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/delete-account.vue")
  },
  {
    name: "diners-diner_uuid",
    path: "/diners/:diner_uuid()",
    meta: _91diner_uuid_93CtK29v9r5nMeta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/diners/[diner_uuid].vue")
  },
  {
    name: "diners-add",
    path: "/diners/add",
    meta: addGQ0mLimg9nMeta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/diners/add.vue")
  },
  {
    name: "diners",
    path: "/diners",
    meta: indexhpOROLNyD8Meta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/diners/index.vue")
  },
  {
    name: "establishments-add",
    path: "/establishments/add",
    meta: addIeqqvwYdc4Meta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/establishments/add.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordxWBJlyGocZMeta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/forgot-password.vue")
  },
  {
    name: "history-orders-order_uuid",
    path: "/history/orders/:order_uuid()",
    meta: _91order_uuid_93P95iBxTHbeMeta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/history/orders/[order_uuid].vue")
  },
  {
    name: "history-orders",
    path: "/history/orders",
    meta: index1PWrJg05fOMeta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/history/orders/index.vue")
  },
  {
    name: "history-transactions-transaction_uuid",
    path: "/history/transactions/:transaction_uuid()",
    meta: _91transaction_uuid_93Ju5YieNsgvMeta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/history/transactions/[transaction_uuid].vue")
  },
  {
    name: "history-transactions",
    path: "/history/transactions",
    meta: indexPToCxjuMdcMeta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/history/transactions/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexof0k9DmzyiMeta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginEqEiYzaod3Meta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/login.vue")
  },
  {
    name: "password-reset-token",
    path: "/password-reset/:token()",
    meta: _91token_93d2Lodfht92Meta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/password-reset/[token].vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: profilelI2Wk6dWfQMeta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/profile.vue")
  },
  {
    name: "signup-success",
    path: "/signup-success",
    meta: signup_45successWNdZ4qSu90Meta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/signup-success.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupJUQQ43Jf60Meta || {},
    component: () => import("/home/forge/app.canteenweb.com/pages/signup.vue")
  },
  {
    name: "update-app",
    path: "/update-app",
    component: () => import("/home/forge/app.canteenweb.com/pages/update-app.vue")
  }
]
<script setup>
const props = defineProps({
  type: {
    type: String,
    default: "submit",
  },
  loading: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    default: "primary",
  },
});

const variantClasses = {
  primary:
    "bg-primary-500  border-transparent text-black focus:border-primary-300 focus:ring-primary-100",
  outline:
    "bg-transparent border-black text-black focus:border-black focus:ring-primary-100",
  white:
    "bg-white border-white text-black focus:border-black focus:ring-primary-100",
  danger:
    "bg-white border-red-500 text-red-500 focus:border-red-300 focus:ring-red-100",
  link: "bg-transparent border-transparent text-black focus:ring-transparent",
};

const currentVariantClasses = variantClasses[props.variant];

const isDisabled = computed(() => props.loading || useAttrs().disabled);
</script>

<template>
  <button
    :type="type"
    :disabled="isDisabled"
    class="relative inline-flex items-center border rounded-md font-medium focus:outline-none focus:ring disabled:opacity-50 transition py-2 px-6 text-md"
    :class="currentVariantClasses"
  >
    <FEButtonLoading :loading="loading" />
    <div class="inline-flex items-center" :class="{ invisible: loading }">
      <slot />
    </div>
  </button>
</template>

/**
 * @typedef {Object} Settings
 * @property {Object} global_message
 * @property {string|null} global_message.id
 * @property {string|null} global_message.text
 * @property {string} organization_name
 * @property {string} locale
 * @property {string} currency
 * @property {boolean} require_customer_photo
 * @property {boolean} allow_account_deletion
 * @property {boolean} allow_credit_topup
 * @property {string} timezone
 * @property {Array<{ uuid: string, name: string }>} classes
 * @property {Array<{
 *   uuid: string,
 *   name: string,
 *   order_closing_time: string,
 *   menu_closing_time: string,
 *   is_open: boolean,
 *   can_purchase_menu: boolean,
 *   closing_days: string[]
 * }>} establishments
 * @property {Object|null} payment_gateway
 * @property {string} payment_gateway.name
 * @property {string} payment_gateway.type
 * @property {string|null} payment_gateway.public_key
 * @property {Object} customer
 * @property {string} customer.uuid
 * @property {number} customer.credit
 * @property {string} customer.given_name
 * @property {string} customer.family_name
 * @property {string} customer.email
 * @property {string} customer.phone
 * @property {string} customer.default_establishment_uuid
 * @property {Array<{
 *   uuid: string,
 *   customer_class_uuid?: string,
 *   class_name?: string,
 *   full_name: string,
 *   given_name: string,
 *   family_name: string,
 *   email: string,
 *   credit: number,
 *   available_daily_credit: number,
 *   spending_limit: number,
 *   profile_photo_url: string,
 *   default_establishment_name?: string,
 *   default_establishment_uuid?: string
 * }>} customer.diners
 * @property {number} checkout_service_fee
 * @property {Array<number>} checkout_amounts
 */

/**
 * @returns {Settings}
 */
export const useSettings = () => {
  return useState("settings", () => /** @type {Settings} */ ({}));
};

export const useEstablishments = () => {
  return computed(() => {
    return useSettings().value.establishments?.map((establishment) => {
      return {
        label: establishment.name,
        value: establishment.uuid,
        settings: establishment,
      };
    });
  });
};

export const useCurrentEstablishment = (establishmentUuid) => {
  return computed(() => {
    return useEstablishments().value.find((e) => e.value === establishmentUuid);
  });
};

export const fetchSettings = async () => {
  try {
    const { data } = await $larafetch("/api/v1/ecommerce/settings", {
      redirectIfNotAuthenticated: false,
    });

    useSettings().value = data;
    return data;
  } catch (error) {
    if ([401, 419].includes(error?.response?.status)) return null;
    throw error;
  }
};

import revive_payload_client_4sVQNw7RlN from "/home/forge/app.canteenweb.com/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/forge/app.canteenweb.com/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/forge/app.canteenweb.com/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/forge/app.canteenweb.com/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/forge/app.canteenweb.com/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/forge/app.canteenweb.com/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/forge/app.canteenweb.com/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/forge/app.canteenweb.com/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/forge/app.canteenweb.com/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/home/forge/app.canteenweb.com/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import _01_app_update_client_4asrzgClxP from "/home/forge/app.canteenweb.com/plugins/01.app-update.client.js";
import auth_ANExhJHvcR from "/home/forge/app.canteenweb.com/plugins/auth.js";
import deepLinks_7bqhtXy2GZ from "/home/forge/app.canteenweb.com/plugins/deepLinks.js";
import money_7e8wnAmXmy from "/home/forge/app.canteenweb.com/plugins/money.js";
import settings_2MnfoqQ5ue from "/home/forge/app.canteenweb.com/plugins/settings.js";
import toastify_Ri0MJIkvMs from "/home/forge/app.canteenweb.com/plugins/toastify.js";
import v_calendar_oQ1bZ1yFoH from "/home/forge/app.canteenweb.com/plugins/v-calendar.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  _01_app_update_client_4asrzgClxP,
  auth_ANExhJHvcR,
  deepLinks_7bqhtXy2GZ,
  money_7e8wnAmXmy,
  settings_2MnfoqQ5ue,
  toastify_Ri0MJIkvMs,
  v_calendar_oQ1bZ1yFoH
]
import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";
import {
  AppUpdate,
  AppUpdateAvailability,
} from "@capawesome/capacitor-app-update";

export default defineNuxtPlugin(() => {
  const IOS_APP_ID = "6504421083"; // App Store ID
  const COUNTRY = "AR"; // Country flag for the App Store API
  const LAST_PROMPTED_VERSION_KEY = "lastPromptedVersion31022025-0"; // Store last prompted version

  const openAppStore = async () => {
    try {
      if (Capacitor.getPlatform() === "ios") {
        await AppUpdate.openAppStore({ appId: IOS_APP_ID });
      } else {
        await AppUpdate.openAppStore();
      }
    } catch (error) {
      console.error("Error opening app store:", error);
    }
  };

  const checkForUpdates = async () => {
    try {
      const result = await AppUpdate.getAppUpdateInfo({ country: COUNTRY });

      if (
        result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE
      ) {
        const latestVersion = result.availableVersionName;

        // Check if we've already prompted for this version
        const lastPromptedVersion = localStorage.getItem(
          LAST_PROMPTED_VERSION_KEY,
        );

        if (lastPromptedVersion === latestVersion) {
          console.log("Already prompted for this version. Skipping...");
          return;
        }

        // Prompt the user
        // if (confirm("A new version is available. Do you want to update?")) {
        // await openAppStore();
        // }

        // Store the prompted version
        localStorage.setItem(LAST_PROMPTED_VERSION_KEY, latestVersion);
        return await navigateTo("/update-app");
      }
    } catch (error) {
      console.error("Error checking for updates:", error);
    }
  };

  // Run update check on app launch
  if (Capacitor.getPlatform() !== "web") {
    checkForUpdates();

    // Also check when app resumes from the background
    App.addListener("appStateChange", async (state) => {
      if (state.isActive) {
        await checkForUpdates();
      }
    });
  }

  return {
    provide: {
      openAppStore,
    },
  };
});
